import { post } from "network";
import { SocialLoginD } from "types/auth";
import { osName, deviceType, isAndroid, isIOS } from "react-device-detect";

export const loginApi = async ({ source = isAndroid ? "android" : isIOS ? "ios" : "web", version = 1, device_info = deviceType, ...raw }: { email: string; password: string; source?: string; version?: number; device_info?: string }) => {
  return post({
    route: "/api/v1/login",
    data: JSON.stringify({ source, version, device_info, ...raw }),
    config: {
      headers: {
        "Content-type": "application/json",
      },
    },
  });
};
export const socialLoginApi = async ({
  source = isAndroid ? "android" : isIOS ? "ios" : "web",
  version = 1,
  device_info = deviceType,
  ...raw
}: {
  user?: string;
  first_name?: string;
  last_name?: string;
  phone_no?: string;
  phone_code?: string;
  email: string;
  firebase_uid: string;
  fcm_token?: string;
  type: SocialLoginD;
  source?: string;
  version?: number;
  device_info?: string;
}) => {
  return post({
    route: "/api/v1/socialLogin",
    data: JSON.stringify({ source, version, device_info, ...raw }),
    config: {
      headers: {
        "Content-type": "application/json",
      },
    },
  });
};

export const signupApi = async ({
  source = osName === "ios" ? "ios" : osName === "android" ? "android" : "web",
  version = 1,
  device_info = deviceType,
  ...raw
}: {
  name?: string;
  first_name?: string;
  last_name?: string;
  phone_no?: string;
  phone_code?: string;
  email: string;
  // organization: "myorg";
  password: "Mypass";
  source?: string;
  version?: number;
  device_info?: string;
}) => {
  return post({
    route: "/api/v1/register",
    data: JSON.stringify({ source, version, device_info, ...raw }),
    config: {
      headers: {
        "Content-type": "application/json",
      },
    },
  });
};

export const verifyEmailApi = async ({ hash }: { hash: string }) => {
  return post({
    route: "/api/v1/verifyEmail",
    data: JSON.stringify({ hash }),
    config: {
      headers: {
        "Content-type": "application/json",
      },
    },
  });
};
export const resendEmailVerificationApi = async ({ email }: { email: string }) => {
  return post({
    route: "/api/v1/resendEmailVerification",
    data: JSON.stringify({ email }),
    config: {
      headers: {
        "Content-type": "application/json",
      },
    },
  });
};

export const generateSSOTokenApi = async ({ token }: { token: string }) => {
  return post({
    route: "/api/v1/generateSSOToken",
    data: JSON.stringify({}),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

export const logoutApi = async ({ token }: { token: string }) => {
  return post({
    route: "/api/v1/logout",
    data: JSON.stringify({}),
    config: {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    },
  });
};

export const getConnectedDiscordAccountApi = async ({ token }: { token: string }) => {
  return post({
    route: "/api/v1/getConnectedDiscordAccount",
    data: JSON.stringify({}),
    config: {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    },
  });
};

export const removeConnectedDiscordAccountApi = async ({ token }: { token: string }) => {
  return post({
    route: "/api/v1/removeConnectedDiscordAccount",
    data: JSON.stringify({}),
    config: {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    },
  });
};
