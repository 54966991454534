import { CircularProgress, Spinner } from "@nextui-org/react";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import { useSearchParams } from "react-router-dom";

export const YourGptAppCard = ({ item }: any) => {
  const [clicked, setClicked] = useState(false);
  const [params] = useSearchParams();
  const continueUrl = params.get("continue");

  useEffect(() => {
    if (continueUrl) {
      window.location.href = continueUrl;
    }
    const timeoutId = setTimeout(() => {
      setClicked(false);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [continueUrl, setClicked]);

  if (continueUrl) {
    return (
      <div className="h-[400px] w-full flex items-center justify-center ">
        <CircularProgress size="sm" />
      </div>
    );
  }
  // const onhandleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
  //   e.preventDefault();
  //   setClicked(true);

  //   setTimeout(() => {
  //     setClicked(false);
  //     window.open(item.link, "_blank");
  //   }, 1000);
  // };

  return (
    <>
      <AnimatePresence>
        {clicked && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="fixed z-[20] backdrop-blur-sm bg-background/10 h-full w-full left-0 top-0  flex justify-center items-center">
            <Spinner />
          </motion.div>
        )}
      </AnimatePresence>
      <div className="flex flex-col justify-center items-center gap-3 bg-white py-10 px-2 w-[385px] md:w-[300px] drop-shadow-sm transition-all duration-700 rounded-md hover:shadow-lg relative">
        {item.beta && <div className="absolute top-0 right-0 bg-yellow-400 text-black text-xs font-semibold px-4 py-2 rounded-bl-md rounded-tr-md">Beta</div>}

        <div className="flex justify-center items-center">{item.icon}</div>
        <a
          href={item.link}
          target="_blank"
          rel="noreferrer"
          className="text-lg font-semibold text-black/80 transition-colors flex justify-center items-center group/title cursor-pointer hover:text-primary1 text-center w-full ml-[12px]"
          // onClick={onhandleClick}
        >
          {item.name}
          <MdArrowForwardIos className="ml-1 group-hover/title:translate-x-1 transition-all opacity-0 group-hover/title:opacity-100" size={14} />
        </a>
        <p className="text-gray-500 text-center w-full flex-1">{item.desc}</p>
        <div className="flex justify-center w-full mt-3">
          <a
            href={item.link}
            rel="noreferrer"
            target="_blank"
            className="bg-primary1 text-sm text-white border-1 py-2 px-4 rounded-md hover:ring-2 hover:ring-primary/20 w-fit cursor-pointer transition-all duration-500"
            // onClick={onhandleClick}
          >
            Access Now
          </a>
        </div>
      </div>
    </>
  );
};
