import React, { useState } from "react";
import { Button, Select, SelectItem, Textarea } from "@nextui-org/react";
import { createDataDeletionRequestApi } from "network/api/app";
import { ApiResType } from "types/enum";
import { useAuth } from "context/AuthContext";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";

const OPTIONS = ["Price/Budget", "Missing features", "Technical issues", "Training", "Privacy", "Support", "Another reason"];

const SUB_OPTIONS = {
  price: ["Too expensive", "Budget constraints", "Financial reasons", "Training"],
  features: ["Needed additional features", "Feature requests", "Not enough customization options"],
  issue: ["Bugs and errors", "Performance issues", "Compatibility issues"],
  domain: ["Changed business focus", "No longer need a website", "Other domain-related reasons"],
  training: ["Trouble training the AI", "AI not performing as expected", "AI not generating relevant content"],
  privacy: ["Privacy concerns", "Data security concerns", "Data protection"],
  support: ["Poor customer support", "Unresponsive customer service"],
};

const Feedback = () => {
  const [reason, setReason] = useState<string>("");
  const [additionalReason, setAdditionalReason] = useState<string>("");
  const [whyReason, setWhyReason] = useState<string>("");
  const [whyReasonOptions, setWhyReasonOptions] = useState<string[]>([]);
  const { token } = useAuth();
  const [err, setErr] = useState("");
  const [modal, setModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [requesting, setRequesting] = useState(false);

  async function onConfirm() {
    setErr("");

    if (!token || !reason) return;

    if (reason === "Another reason") {
      //reason should not be less than 10 characters
      if (additionalReason.length < 12) {
        setErr("Reason should be at least 12 characters long");
        return;
      }
    }

    try {
      setRequesting(true);

      const r = additionalReason ? `${reason}\n${additionalReason}` : `Reason: ${reason}\nDescription: ${whyReason}`;

      const res = await createDataDeletionRequestApi({ token, reason: r });

      setRequesting(false);
      if (res?.type === ApiResType.SUCCESS) {
        setSuccess(true);
        setReason("");
        setErr("");
      }
      if (res?.type === ApiResType.ERROR) {
        setErr(res.message);
      }
      setModal(false);
    } catch (e) {
      setRequesting(false);
      setModal(false);
      console.log(e, "error");
      setErr("Something unexpected happened");
    }
  }

  const handleReasonChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedReason = event.target.value;
    console.log("selectedReason", selectedReason);
    setReason(selectedReason);
    // Update whyReasonOptions based on selected reason
    switch (selectedReason) {
      case "Price/Budget":
        setWhyReasonOptions(SUB_OPTIONS.price);
        break;
      case "Missing features":
        setWhyReasonOptions(SUB_OPTIONS.features);
        break;
      case "Technical issues":
        setWhyReasonOptions(SUB_OPTIONS.issue);
        break;
      case "Training":
        setWhyReasonOptions(SUB_OPTIONS.training);
        break;
      case "Domain":
        setWhyReasonOptions(SUB_OPTIONS.domain);
        break;
      case "Privacy":
        setWhyReasonOptions(SUB_OPTIONS.privacy);
        break;
      case "Support":
        setWhyReasonOptions(SUB_OPTIONS.support);
        break;
      default:
        // Default options or handle 'Another reason' case
        setWhyReasonOptions([]);
        break;
    }
    // Reset whyReason when reason changes
    setWhyReason("");
  };

  const handleAdditionalReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdditionalReason(event.target.value);
  };

  const handleWhyReasonChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setWhyReason(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Reset the form (optional)
    // setReason("");
    // setAdditionalReason("");
    // setWhyReason("");
  };

  const isSubmitDisabled = reason === "Another reason" ? !additionalReason : !whyReason;

  return (
    <div className="min-h-screen flex flex-col items-center py-12 px-4 sm:px-6 lg:px-8">
      {success ? (
        <div className="border-success-400 border rounded-medium p-4 bg-success-50 max-w-lg">
          <h4 className="text-success-500 font-bold text-lg mb-2">Success!</h4>

          <p className="text-foreground-600 text-sm md:text-base">
            We've received your account deletion request. Our team is now processing it with care. You will be notified once the process is complete. Thank you for being a part of our community, and we appreciate your understanding.
          </p>
        </div>
      ) : (
        <div className="max-w-xl w-full space-y-8 flex flex-col gap-8">
          <div className="flex flex-col gap-4">
            <h2 className="mt-6 text-center text-2xl md:text-3xl font-extrabold text-gray-900">Share Your Feedback</h2>
            <p className="mt-2 text-center text-xs md:text-sm text-gray-600">Before you go, please answer a few quick questions to help us improve YourGPT</p>
          </div>
          <form className="mt-8 space-y-6 p-1 md:p-0" onSubmit={handleSubmit}>
            <div>
              <div className="w-full flex flex-row flex-wrap gap-4">
                <Select
                  radius={"sm"}
                  label="WHY WOULD YOU LIKE TO DELETE YOUR ACCOUNT?"
                  placeholder="Select your reason"
                  labelPlacement="outside"
                  className="max-w-full mb-2 "
                  classNames={{
                    label: "text-xs md:text-sm",
                  }}
                  onChange={handleReasonChange}
                  autoFocus={false}
                >
                  {OPTIONS.map((item: any) => (
                    <SelectItem key={item}>{item}</SelectItem>
                  ))}
                </Select>
              </div>

              {reason === "Another reason" && (
                <div>
                  <Textarea id="additional-reason" name="additional-reason" value={additionalReason} onChange={handleAdditionalReasonChange} isRequired label="Reason" placeholder="Can you please specify ?" radius="sm" size="sm" />
                </div>
              )}
              {reason !== "Another reason" && (
                <div className="rounded-md shadow-sm -space-y-px mt-6">
                  <div>
                    <Select
                      radius={"sm"}
                      onChange={handleWhyReasonChange}
                      label=" WHY IS THAT ?"
                      labelPlacement="outside"
                      className="max-w-full"
                      placeholder="Select your reason"
                      classNames={{
                        label: "text-xs md:text-sm",
                      }}
                    >
                      {whyReasonOptions.map((option) => (
                        <SelectItem key={option} value={option}>
                          {option}
                        </SelectItem>
                      ))}
                    </Select>
                  </div>
                </div>
              )}
            </div>
            {err && (
              <div className="max-w-full rounded-md w-full flex text-xs md:text-sm text-red-500  justify-between items-center">
                {err}
                <div onClick={() => setErr("")} className="text-xl pr-1 cursor-pointer ">
                  <IoClose />
                </div>
              </div>
            )}

            <div className="flex gap-2">
              <Button type="submit" className="px-3" onClick={onConfirm} isLoading={requesting} isDisabled={isSubmitDisabled} variant="solid" color="danger">
                Delete my account
              </Button>
              <Button as={Link} to={"/products"}>
                Back
              </Button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Feedback;
