import { generateSSOTokenApi } from "network/api/auth";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ApiResType } from "types/enum";
import Cookies from "js-cookie";
import { STORAGE_KEYS } from "utils/constants/app";

export default function EpicxplorerSSO() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = Cookies.get(STORAGE_KEYS.token);

  const returnToUrl = searchParams.get("return_to");
  const hostname = new URL(returnToUrl || "").hostname;

  useEffect(() => {
    console.log("token", token);
    if (!token) {
      navigate(
        `/login?redirect_url=https://app.yourgpt.ai/sso/epicxplorer?return_to=${returnToUrl}`
      );
    }

    async function generateToken() {
      try {
        const res = await generateSSOTokenApi({
          token: token || "",
        });

        console.log(res?.data, "res");
        if (res.type === ApiResType.SUCCESS) {
          window.location.href = `https://${hostname}/sso/success?token=${res?.data?.token}&return_to=${returnToUrl}`;
        }
      } catch (error) {
        console.log(error);
      }
    }
    generateToken();
  }, [token, navigate, returnToUrl, hostname]);

  return <></>;
}
