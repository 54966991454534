import { format } from "date-fns";
import { UserDetailsD } from "types/user";

export const log = (...args: any[]) => {
  // return console.log(...args);
};
export const logErr = (...args: any[]) => {
  return console.error(...args);
};

export const formatDateTime = (tm: number | string) => {
  if (typeof tm === "number") {
    return format(new Date(tm * 1000), "dd,MMM yyyy , HH:MM");

    // return new Date(tm * 1000).toLocaleString();
  } else if (tm) {
    return format(new Date(tm), "dd,MMM yyyy , HH:MM");
    // return new Date(tm).toLocaleString();
  }
};

export function isValidURL(str: string): boolean {
  const regex = /^(ftp|http|https):\/\/[^ "]+$/;
  return regex.test(str);
}
export function isValidFileType(str: string): boolean {
  const regex = /\.txt$|\.pdf$|\.doc$|\.docx$|\.ppt$|\.csv$/i;
  return regex.test(str);
}

export function getMediumDarkColorHex(firstLetter: any) {
  const colorMap: any = {
    a: "#3498db",
    b: "#8e44ad",
    c: "#e74c3c",
    d: "#2ecc71",
    e: "#f39c12",
    f: "#d35400",
    g: "#27ae60",
    h: "#2980b9",
    i: "#9b59b6",
    j: "#c0392b",
    k: "#1abc9c",
    l: "#e67e22",
    m: "#2c3e50",
    n: "#7f8c8d",
    o: "#34495e",
    p: "#f1c40f",
    q: "#16a085",
    r: "#d35400",
    s: "#95a5a6",
    t: "#27ae60",
    u: "#bdc3c7",
    v: "#9b59b6",
    w: "#8e44ad",
    x: "#3498db",
    y: "#e74c3c",
    z: "#1abc9c",
  };

  const firstLetter1 = typeof firstLetter === "string" ? firstLetter[0]?.toLowerCase() : firstLetter?.toLowerCase();
  return colorMap[firstLetter1] || "#333333";
}

export const getUserName = (u: UserDetailsD): string => {
  if (!u) return "";
  return u?.name || `User${(Number(u.id || 0) * 1.3).toFixed(0)}`;
};
