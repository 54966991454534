import { post } from "network";
import { ListingApiParams, SortD } from "types";
import { Mode } from "types/playground";
import { ProjectTypeD } from "types/project";

export const getProjectsApi = async ({ token, ...raw }: ListingApiParams & { projectId?: any }) => {
  return post({
    route: "/api/v1/getMyProjects",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
// export const createProjectApi = async ({ token, ...raw }: { token: string; name: string; organization_id: string; type: ProjectTypeD }) => {
//   return post({
//     route: "/api/v1/createProject",
//     data: JSON.stringify(raw),
//     config: {
//       headers: {
//         Authorization: "Bearer " + token,
//         "Content-type": "application/json",
//       },
//     },
//   });
// };

type createProjectBodyD = {
  token: string; 
  name: string; 
  organization_id: string; 
  type: ProjectTypeD;
  purpose?: string;
  website_url?: string;
}

export const createProjectApi = async ({ token, ...raw }: createProjectBodyD ) => {
  return post({
    route: "/api/v1/createProject",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

export const updateProjectApi = async ({ token, ...raw }: { token: string; name: string; project_uid: any }) => {
  return post({
    route: "/api/v1/updateProject",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

type PlaygroundApi = {
  project_uid: string;
  model: string;
  prompt: string;
  mode?: Mode;
  suffix?: string;
  max_tokens?: string;
  temperature?: string;
  top_p?: string;
  n?: string;
  stream?: boolean;
  logprobs?: string;
  echo?: string;
  stop?: string;
  presence_penalty?: string;
  frequency_penalty?: string;
  best_of?: string;
  logit_bias?: string;
  user?: string;
  messages: string;
  inject_restart_text?: string;
};

export const playgroundApi = async ({ token, ...raw }: { token: string; project_uid: string } & PlaygroundApi) => {
  return post({
    route: "/api/v1/basictuning/playground",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

type AdvancedPlaygroundApi = {
  project_uid: string;
  query: string;
  model_name?: string; 
  temperature?: string;  
};
export const advancePlaygroundApi = async ({ token, ...raw }: { token: string } & AdvancedPlaygroundApi) => {

  return post({
    route: "/api/v1/advancePlayground",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

export const getProjectDetailApi = async ({ token, ...raw }: { token: string; project_uid: any }) => {
  return post({
    route: "/api/v1/getProjectDetail",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const deleteProjectApi = async ({ token, ...raw }: { token: string; project_uid: any }) => {
  return post({
    route: "/api/v1/deleteProject",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const updateProjectSettingApi = async ({ token, ...raw }: { token: string; project_uid: any; model: string; max_tokens: any; temperature: any; stop: any; prompt_suffix: any }) => {
  return post({
    route: "/api/v1/updateProjectSettings",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const getProjectDomainsApi = async ({ token, ...raw }: { token: string; project_uid: any; limit: number; page: number; search?: string }) => {
  return post({
    route: "/api/v1/getProjectDomains",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const createProjectDomainApi = async ({ token, ...raw }: { token: string; project_uid: any; domain: string }) => {
  return post({
    route: "/api/v1/createProjectDomain",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const deleteProjectDomainApi = async ({ token, ...raw }: { token: string; project_uid: any; id: any }) => {
  return post({
    route: "/api/v1/deleteProjectDomain",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const generateProjectKeyApi = async ({ token, ...raw }: { token: string; project_uid: any; name: string }) => {
  return post({
    route: "/api/v1/generateProjectKey",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const activateProjectKeyApi = async ({ token, ...raw }: { token: string; project_uid: any; id: any }) => {
  return post({
    route: "/api/v1/activateProjectKey",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const deactivateProjectKeyApi = async ({ token, ...raw }: { token: string; project_uid: any; id: any }) => {
  return post({
    route: "/api/v1/deactivateProjectKey",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const deleteProjectKeyApi = async ({ token, ...raw }: { token: string; project_uid: any; id: any }) => {
  return post({
    route: "/api/v1/deleteProjectKey",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
