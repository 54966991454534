import { CssVarsProvider } from "@mui/joy";
import { Toaster } from "react-hot-toast";
import { Toaster as SToaster } from "sonner";
import { RouterProvider } from "react-router-dom";
import { router } from "router";
import { NextUIProvider } from "@nextui-org/react";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { POSTHOG_HOST, POSTHOG_KEY } from "utils/constants/config";

posthog.init(POSTHOG_KEY, { api_host: POSTHOG_HOST });

function App() {
  return (
    <PostHogProvider client={posthog}>
      <NextUIProvider>
        <div className="App">
          <Toaster />
          <SToaster />
          <CssVarsProvider
            defaultMode="light"
            //
            // the local storage key to use
            modeStorageKey="joyTheme"
            //
            // set as root provider
            // disableNestedContext
          >
            <RouterProvider router={router} />
          </CssVarsProvider>
        </div>
      </NextUIProvider>
    </PostHogProvider>
  );
}

export default App;
