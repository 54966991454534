import React from "react";
import { Link, Navigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/svg/logo-white.svg";
// import Cookies from "js-cookie";
// import { STORAGE_KEYS } from "utils/constants/app";

export default function AuthLayout({ children }: { children: React.ReactNode }) {
  return (
    <>
      {/* {Cookies.get(STORAGE_KEYS.token) ? (
        <Navigate to={"/products"} />
      ) : ( */}
      <div className="min-h-screen flex">
        <div className="flex-1 px-6 hidden sm:flex bg-primary1 flex-col justify-center items-center">
          <div>
            <a href="https://yourgpt.ai" className="self-start mb-4">
              <Logo className="h-10 w-auto" />
              <div className=" font-bold text-white/40">YOUR GPT</div>
            </a>
            <div className="flex flex-col max-w-[540px] self-stretch ">
              <img className="h-[320px] self-center mb-4" src={require("../../assets/images/auth-image.png")} alt="ai" />

              <h2 className="font-regular text-white text-2xl">Empower your business with the transformative</h2>
              <h2 className=" font-bold text-yellow1 text-4xl">Power of AI technology!</h2>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col ">
          <div className="flex-1 flex justify-center items-center">{children}</div>

          <div className="flex gap-2 justify-center py-3 pb-6 text-sm">
            <Link to={"https://yourgpt.ai/terms"} className="text-black/60 hover:text-black transition-all">
              Terms and conditions
            </Link>{" "}
            •{" "}
            <Link className="text-black/60 hover:text-black transition-all" to={"https://yourgpt.ai/privacy"}>
              Privacy policy
            </Link>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
}
