import { CircularProgress, Alert } from "@mui/joy";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { log } from "utils/helpers";
import { verifyEmailApi } from "network/api/auth";
import { ApiResType } from "types/enum";
import { toast } from "react-hot-toast";

export default function VerifyEmail() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        setLoading(true);
        const res = await verifyEmailApi({ hash: params.verifyHash || "" });
        setLoading(false);

        if (res.type === ApiResType.SUCCESS) {
          toast.success("Email verified, please login");
          navigate("/login");
        } else {
          setError(res.message);
          // toast.error(res.message);

          setTimeout(() => {
            navigate("/login");
          }, 3000);
        }

        log(res);
      } catch (err) {
        setLoading(false);
        console.error("Err", err);
      }
    };

    if (params.verifyHash) {
      verifyEmail();
    }

    log(params);
  }, [params, navigate]);

  return (
    <div>
      <Helmet>
        <title>Verify Email</title>
      </Helmet>

      <div>
        <div className="py-12 flex justify-center items-center">
          {error && <Alert color="danger">{error}</Alert>}

          {loading && (
            <div className="flex items-center justify-center flex-col gap-6">
              <CircularProgress />
              <div className="font-medium">Verifying your email ...</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
