import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getProjectsApi } from "network/api/project";
import { RootState } from "store";
import { ApiResType } from "types/enum";
import { ProjectItemD } from "types/project";

interface CounterState {
  loading: boolean;
  list: ProjectItemD[];
  error: string;
}

const initialState: CounterState = {
  list: [],
  loading: false,
  error: "",
};

export const fetchProjectsThunk = createAsyncThunk("project/fetchProjects", async ({ token, search }: { token: string; search: string }, thunkAPI) => {
  try {
    const res = await getProjectsApi({ token, search, limit: 10, page: 1 });
    if (res.type === ApiResType.SUCCESS) {
      return thunkAPI.fulfillWithValue(res.data);
    } else {
      return thunkAPI.rejectWithValue(res);
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const counterSlice = createSlice({
  name: "projects",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setProjects: (state, action: PayloadAction<ProjectItemD[]>) => {
      return {
        ...state,
        list: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectsThunk.fulfilled, (state, action) => {
      state.list = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchProjectsThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchProjectsThunk.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { setProjects } = counterSlice.actions;

export default counterSlice.reducer;
