// components/ImageCropper.tsx
import React, { useState, useCallback, useEffect } from "react";
import Cropper from "react-easy-crop";
import { Button, Slider } from "@nextui-org/react";
import { Area, Point } from "react-easy-crop/types";
import { IoIosCrop } from "react-icons/io";
import { MdRotateLeft, MdRotateRight } from "react-icons/md";

interface ImageCropperProps {
  image: string;
  onCropComplete: (croppedImage: Blob) => void;
  onCancel: () => void;
}

const ImageCropper: React.FC<ImageCropperProps> = ({
  image,
  onCropComplete,
  onCancel,
}) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [rotation, setRotation] = useState<number>(0);

  const onCropChange = (crop: Point) => {
    setCrop(crop);
  };

  const onZoomChange = (zoom: number) => {
    setZoom(zoom);
  };

  const onCroppingComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const createCroppedImage = useCallback(async () => {
    try {
      if (croppedAreaPixels) {
        const croppedImage = await getCroppedImg(
          image,
          croppedAreaPixels,
          rotation
        );
        onCropComplete(croppedImage);
      }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, onCropComplete, image, rotation]);

  return (
    <div>
      <div className="relative">
        <div className="h-80 w-80">
          <Cropper
            image={image}
            crop={crop}
            cropShape="round"
            objectFit="contain"
            zoom={zoom}
            minZoom={1}
            maxZoom={5}
            aspect={1}
            rotation={rotation}
            onRotationChange={setRotation}
            onCropChange={onCropChange}
            onZoomChange={onZoomChange}
            onCropComplete={onCroppingComplete}
          />
        </div>
      </div>
      <div className="py-4 px-0 grid grid-cols-[1fr_auto] md:gap-8">
        <div className="flex gap-3 flex-col justify-between md:flex-row-reverse md:justify-start md:items-center  flex-grow md:gap-8">
          <Slider
            minValue={1}
            maxValue={5}
            value={zoom}
            size="sm"
            step={0.025}
            onChange={(value) => setZoom(Number(value))}
            className=" pr-4 md:p-0 "
          />

          <Button
            variant="flat"
            radius="sm"
            onClick={onCancel}
            color="danger"
            size="sm"
            className="font-semibold w-fit "
          >
            Cancel
          </Button>
        </div>

        <div className="flex flex-col md:flex-row md:flex-grow md:justify-end  gap-3 md:gap-8">
          <div className="flex justify-around md:gap-3 items-center">
            <MdRotateLeft
              onClick={() => setRotation((prev) => Number((prev - 90) % 360))}
              className="text-2xl text-gray-700 active:scale-[1.04] hover:cursor-pointer"
            />

            <MdRotateRight
              onClick={() => setRotation((prev) => Number((prev + 90) % 360))}
              className="text-2xl text-gray-700 active:scale-[1.04] hover:cursor-pointer "
            />
          </div>

          <Button
            variant="flat"
            endContent={<IoIosCrop size={20} strokeWidth={3} />}
            radius="sm"
            onClick={createCroppedImage}
            color="primary"
            size="sm"
            className="font-semibold"
          >
            Crop
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImageCropper;

// Helper functions
const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = url;
  });

const getRadianAngle = (degreeValue: number) => {
  return (degreeValue * Math.PI) / 180;
};

const rotateSize = (
  width: number,
  height: number,
  rotation: number
): { width: number; height: number } => {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
};

const getCroppedImg = async (
  imageSrc: string,
  croppedArea: Area,
  rotation = 0,
  flip = { horizontal: false, vertical: false },
  cropSize?: { width: number; height: number }
): Promise<Blob> => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("Unable to get 2D context");
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // calculate the position and size of the crop
  const targetWidth = cropSize
    ? (croppedArea.width / 100) * bBoxWidth
    : croppedArea.width;
  const targetHeight = cropSize
    ? (croppedArea.height / 100) * bBoxHeight
    : croppedArea.height;
  const targetX = cropSize ? (croppedArea.x / 100) * bBoxWidth : croppedArea.x;
  const targetY = cropSize ? (croppedArea.y / 100) * bBoxHeight : croppedArea.y;
  const finalWidth = cropSize ? cropSize.width : croppedArea.width;
  const finalHeight = cropSize
    ? (targetHeight * finalWidth) / targetWidth
    : croppedArea.height;

  // create a new canvas for the cropped image
  const croppedCanvas = document.createElement("canvas");
  const croppedCtx = croppedCanvas.getContext("2d");

  if (!croppedCtx) {
    throw new Error("Unable to get 2D context");
  }

  croppedCanvas.width = finalWidth;
  croppedCanvas.height = finalHeight;

  // draw the cropped image
  croppedCtx.drawImage(
    canvas,
    targetX,
    targetY,
    targetWidth,
    targetHeight,
    0,
    0,
    finalWidth,
    finalHeight
  );

  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      } else {
        reject(new Error("Canvas is empty"));
      }
    }, "image/jpeg");
  });
};
