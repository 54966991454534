export const ProductExtensionCard = ({ productextension }: any) => {
  return (
    <div className=" bg-white rounded-md hover:shadow-lg overflow-hidden transition-all duration-700 ">
      <div className="flex flex-col justify-center items-center xl:flex-row md:justify-between gap-6  p-2 md:p-4">
        <div className=" flex flex-col justify-between md:flex-row gap-6 md:gap-4 w-full p-2">
          <div className="flex justify-between  items-center w-fit  gap-4  self-center h-fit space-y-1  ">
            <img className="h-14 md:h-18 lg:h-18 object-contain aspect-square  self-start md:self-center" src={productextension.imageUrl} alt="productextension" />

            <div>
              {" "}
              <a href={productextension.link} target="_blank" rel="noreferrer" className=" font-bold text-gray-800 hover:text-primary1  cursor-pointer">
                {productextension.name}
              </a>
              <p className=" text-sm text-gray-500">{productextension.description}</p>
            </div>
          </div>

          {productextension.app ? (
            <div className="flex gap-2 items-center">
              <a href="https://play.google.com/store/apps/details?id=com.yourgpt.chatbot" target="_blank" rel="noreferrer">
                <img src="/images/svg/app/Google.svg" alt="scanner" className="h-[40px]" />
              </a>
              <a className="relative" href="https://apps.apple.com/in/app/your-ai-chatbot/id6499470819" target="_blank" rel="noreferrer">
                <img src="/images/svg/app/Apple.svg" alt="googlePlay" className="h-[40px]" />
              </a>
            </div>
          ) : (
            <a
              href={productextension.link}
              target="_blank"
              rel="noreferrer"
              className=" w-full md:w-fit self-end md:self-center 2xl:self-center xl:mt-0 bg-primary1 text-white px-4 py-2 rounded-md  text-sm   hover:ring-4 hover:ring-primary/20 text-center transition-all duration-500   "
            >
              {productextension.buttonText}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
