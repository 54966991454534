import { Alert, Button, Checkbox, FormControl, FormLabel, Input, Typography } from "@mui/joy";
import { signupApi } from "network/api/auth";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import AuthLayout from "components/layouts/AuthLayout";
import { Helmet } from "react-helmet";
import { logErr } from "utils/helpers";
import { toast } from "react-hot-toast";
import { STORAGE_KEYS } from "utils/constants/app";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useAuth } from "context/AuthContext";
import SocialLoginBtns from "components/SocialLoginBtns";

export default function Signup() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const { onSignup } = useAuth();

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [phone, setPhone] = useState({
    dialcode: "",
    number: "",
  });

  const onSubmit = async (event: any) => {
    setError("");
    event.preventDefault();

    const formElements = event.currentTarget.elements;
    const data = {
      first_name: formElements.first_name.value,
      last_name: formElements.last_name.value,
      phone,
      email: formElements.email.value,
      // organization: formElements.organization.value,
      password: formElements.password.value,
    };

    let full_name = data.last_name ? data.first_name + " " + data.last_name : data.first_name;
    try {
      setLoading(true);
      const res = await signupApi({
        email: data.email,
        name: full_name,
        first_name: data.first_name,
        last_name: data.last_name,
        phone_no: data.phone.dialcode,
        phone_code: data.phone.number,
        // organization: data.organization,
        password: data.password,
      });
      setLoading(false);

      if (res.type === "RXERROR") {
        setError(res.message);
      }

      if (res.type === "RXSUCCESS") {
        onSignup({ email: data.email });

        toast.success("Account created successfully");
        localStorage.setItem(STORAGE_KEYS.email, res?.data?.email);
        navigate("/login?verify=true");
        return;
      }
    } catch (err) {
      setLoading(false);
      setError("Something went wrong try again");
      logErr("Err", err);
    }
  };

  function phoneChange(value: any, data: any) {
    setPhone({
      dialcode: data.dialCode,
      number: value.slice(data.dialCode.length),
    });
  }

  return (
    <AuthLayout>
      <div className="w-[90%] max-w-[450px]">
        <Helmet>
          <title>Create Account - YourGPT</title>
          <meta
            name="description"
            content="Sign up for YourGPT and unlock exclusive benefits. Create your account in just a few simple steps and enjoy personalized recommendations, fast checkout, and more. Our secure signup process ensures your information is safe and protected. Join our community today and start enjoying the perks!"
          />
        </Helmet>
        <Typography fontWeight="xl" level="h4">
          Create your account
        </Typography>
        <Typography
          sx={{
            mb: 2,
            color: "text.secondary",
          }}
        >
          Let's get started! Please enter your details.
        </Typography>

        <form className="flex flex-col gap-4" onSubmit={onSubmit}>
          <div className="flex justify-between flex-col md:flex-row gap-4">
            <FormControl required>
              {/* <FormLabel>First Name</FormLabel> */}
              <Input className="flex-1" placeholder="First Name" type="text" name="first_name" />
            </FormControl>
            <FormControl>
              {/* <FormLabel>Last Name</FormLabel> */}
              <Input className="flex-1" placeholder="Last Name" type="text" name="last_name" />
            </FormControl>
          </div>
          <div className="w-full">
            <PhoneInput onChange={(value, data) => phoneChange(value, data)} inputStyle={{ width: "100%", height: "40px" }} country={"in"} />
          </div>

          <FormControl required>
            <Input placeholder="Enter your email" type="email" name="email" />
          </FormControl>

          <FormControl required>
            <Input
              placeholder="•••••••"
              type={showPassword ? "text" : "password"}
              name="password"
              endDecorator={<div className="flex cursor-pointer items-center">{showPassword ? <AiFillEye onClick={() => setShowPassword(false)} /> : <AiFillEyeInvisible onClick={() => setShowPassword(true)} />}</div>}
            />
          </FormControl>
          <FormControl required>
            <div className="flex gap-2">
              <Checkbox />
              <div>
                I agree to the <span className="text-blue-500">Terms of services</span> and <span className="inline text-blue-500">Privacy Policies</span>
              </div>
            </div>
          </FormControl>

          {/* <FormControl required error={errors.rePassword ? true : false}>
            <FormLabel>Re-type Password</FormLabel>
            <Input placeholder="•••••••" type="password" name="rePassword" />
            {errors?.rePassword && (
              <FormHelperText>Password not matched</FormHelperText>
            )}
          </FormControl> */}

          {/* <FormControl required>
            <FormLabel>Organisation</FormLabel>
            <Input
              placeholder="Organisation"
              type="text"
              name="organization"
              defaultValue={"Default"}
            />
          </FormControl> */}

          {error && (
            <Alert color="danger" variant="soft">
              {error}
            </Alert>
          )}

          <Button type="submit" fullWidth loading={loading}>
            Create account
          </Button>
        </form>

        <div className="flex justify-center py-4">
          <Typography fontWeight={"md"}>
            Already have an account?{" "}
            <Link className="text-blue-500" to={`/login${searchParams.get("continue") ? `?continue=${searchParams.get("continue")}` : ""}`}>
              Login
            </Link>
          </Typography>
        </div>

        <div className="mt-2">
          <SocialLoginBtns />
        </div>
      </div>
    </AuthLayout>
  );
}
