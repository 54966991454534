import { Link } from "react-router-dom";
import ygptLogo from "../assets/svg/you-logo-white.svg";
import { VscDebugDisconnect } from "react-icons/vsc";
import { useAuth } from "context/AuthContext";

export default function ConnectToDiscord() {
  const { token } = useAuth();
  return (
    <div className="max-w-[100vw] min-h-screen flex justify-center items-center bg-gradient-to-br from-slate-900 to-black">
      <div className="max-w-[90vw] w-[500px] text-white bg-gray-500/10  rounded-xl border border-white/10">
        <div className="flex flex-col text-center p-10">
          <div className="text-xl font-semibold">Connect to Discord</div>
          <div className="text-sm opacity-70">Your Discord account will be connected to YourGPT</div>

          <div className="flex justify-center items-center gap-10 my-6">
            <div className="rounded-full  aspect-square flex items-center justify-center p-6">
              <img src={ygptLogo} className="h-16" alt="discord" />
            </div>
            <VscDebugDisconnect className="size-10 rotate-45 opacity-60" />
            <div className="rounded-full flex items-center justify-center aspect-square p-5">
              <img src="/images/discord.png" className="h-14" alt="discord" />
            </div>
          </div>

          <div className="mt-3 flex justify-center items-center gap-5">
            <Link
              to={`https://discord.com/oauth2/authorize?client_id=1282683978167615530&response_type=code&redirect_uri=https%3A%2F%2Fapi.yourgpt.ai%2Fapi%2Fv1%2Fdiscord%2Fconnected-account%2Fredirect&scope=identify+email&state=${token}`}
              target="_blank"
              className="text-white font-medium px-12 py-3 rounded-xl duration-200 inline-block bg-[#5865F2] hover:bg-[#5865F2]/80 transition-all hover:ring-4 hover:ring-[#5865F2]/20"
            >
              Connect
            </Link>
            <Link to="/profile" className="text-white hover:text-white/80 hover:bg-gray-500/20 font-medium px-14 py-3 rounded-xl duration-200 inline-block bg-gray-500/15 transition-all">
              Back
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
