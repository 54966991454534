import Cookies from "js-cookie";
import { STORAGE_KEYS } from "utils/constants/app";
import { logErr } from "utils/helpers";

export const ENDPOINT = process.env.REACT_APP_LOCAL_ENDPOINT ? process.env.REACT_APP_LOCAL_ENDPOINT : "https://api.yourgpt.ai";

export const post = ({ route, data, config }: { route: string; data: any; config?: { headers: any } }) => {
  return fetch(ENDPOINT + route, {
    method: "POST", // or 'PUT'
    headers: {
      ...config?.headers,
    },
    body: data,
  })
    .then(async (response) => {
      if (response?.status === 401) {
        Cookies.remove(STORAGE_KEYS.token, { domain: "yourgpt.ai" });
        Cookies.remove(STORAGE_KEYS.userId, { domain: "yourgpt.ai" });
        window.location.href = "https://app.yourgpt.ai/login";
      }
      const jst = await response.json();
      return jst;
    })
    .catch((err) => {
      // log('POST err', err);
      throw err;
    });
};
export const patch = ({ route, data, config }: { route: string; data: any; config: { headers: any } }) => {
  return fetch(ENDPOINT + route, {
    method: "PATCH", // or 'PUT'
    headers: {
      ...config.headers,
    },
    body: data,
  })
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        const err = await response.json();
        throw err;
      }
    })
    .catch((err) => {
      // log('POST err', err);
      throw err;
    });
};
export const get = ({ route, config }: { route: string; config: { headers: any } }) => {
  return fetch(ENDPOINT + route, {
    method: "GET", // or 'PUT'
    ...config,
  })
    .then((response) => response.json())
    .catch((err) => logErr(err));
};
export const put = ({ route, data, config }: { route: string; data: any; config: { headers: any } }) => {
  return fetch(ENDPOINT + route, {
    method: "PUT",
    headers: {
      ...config.headers,
    },
    body: data,
  })
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        const err = await response.json();
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
};
export const deleteReq = ({ route, config }: { route: string; config: { headers: any } }) => {
  return fetch(ENDPOINT + route, {
    method: "DELETE",
    headers: {
      ...config.headers,
    },
  })
    .then(async (response) => {
      if (response.ok) {
        return response.text();
      } else {
        const err = await response.json();
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
};
