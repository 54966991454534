import { CircularProgress } from "@mui/joy";
import { useAuth } from "context/AuthContext";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { LoginApiResD } from "types/apiResponse";
import { UserProfileD } from "types/user";

export default function ProcessLogin() {
  const { onLoginSuccess } = useAuth();
  const params = useParams();

  useEffect(() => {
    if (typeof params.loginToken === "string") {
      onLoginSuccess({ data: { token: params.loginToken } as UserProfileD & LoginApiResD, persist: false });
    }
  }, [params, onLoginSuccess]);

  return (
    <div className="h-screen w-full flex items-center justify-center">
      <div className="flex gap-2 items-center">
        <CircularProgress size="sm" />
        Loggin in...
      </div>
    </div>
  );
}
