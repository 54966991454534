import { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../assets/svg/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { useAuth } from "context/AuthContext";
import { Menu, MenuItem } from "@mui/joy";
import { GiHamburgerMenu } from "react-icons/gi";
import { FiChevronDown } from "react-icons/fi";
import { THEME } from "utils/constants/ui";
import { Avatar, Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react";
import { RiAccountCircleFill } from "react-icons/ri";
import { appContent } from "./variants/app";

export default function BackupNavbar() {
  const navigate = useNavigate();
  const { logout, user } = useAuth();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const menuOpen = Boolean(anchorEl);
  const [referrer, setReferrer] = useState("");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 10) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setReferrer(document.referrer);
  }, []);

  return (
    <Root className={`${appContent({ class: `flex sticky top-0 z-[20] justify-between items-center [&.scrolled]:shadow-[0_4px_20px_rgba(0,0,0,0.05)] [&.scrolled]:bg-background  ${scrolled ? "scrolled" : ""} ` })} `}>
      <Link to="/products">
        <Logo className={` h-9 ${scrolled ? "fill-foreground-800" : "fill-white"}`} />
      </Link>

      <div className="ml-auto">
        <Dropdown size="lg" placement="bottom-end">
          <DropdownTrigger>
            <Button
              variant="flat"
              className={` ${scrolled ? "scrolled" : ""} [&.scrolled]:text-foreground-800 text-white`}
              startContent={<Avatar className="h-6 w-6 text-current" src={user?.profile_pic} />}
              endContent={<FiChevronDown className="text-current" />}
            >
              Account
            </Button>
          </DropdownTrigger>
          <DropdownMenu aria-label="Static Actions">
            <DropdownItem
              onClick={() => {
                navigate("/profile");
              }}
              key="new"
            >
              Profile
            </DropdownItem>
            <DropdownItem onClick={logout} key="delete" className="text-danger" color="danger">
              Logout
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </Root>
  );
}

const Root = styled.nav`
  height: ${THEME.navbarHeight}px;
`;
