import React from "react";
import { FaGlobe } from "react-icons/fa";
import { HiUserCircle } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

const LeftNavbar = () => {
  const path = window.location.pathname;
  const navigate = useNavigate();
  return (
    <aside className="flex md:flex-col bg-gray-950 text-white w-full md:w-[100px] fixed md:min-h-[100vh] top-[60px] z-10 ">
      <div className={`flex flex-1 md:flex-grow-0 flex-col items-center justify-center gap-2 py-3  cursor-pointer  ${path === "/products" ? cssinpath : cssoutpath} `} onClick={() => navigate("/products")}>
        <FaGlobe className="text-xl  " />
        <h2 className="text-sm ">All Apps</h2>
      </div>
      <div className={`flex flex-1 md:flex-grow-0 flex-col items-center justify-center gap-2 py-3  cursor-pointer ${path === "/profile" ? cssinpath : cssoutpath}`} onClick={() => navigate("/profile")}>
        <HiUserCircle className="text-2xl" />
        <h2 className="text-sm ">Account</h2>
      </div>
    </aside>
  );
};

export default LeftNavbar;

const cssinpath = "bg-primary-500 hover:bg-gradient-to-tr from-gray-200/15 transition-all duration-500 ";
const cssoutpath = "hover:bg-gray-200/25 transition-all duration-300";
