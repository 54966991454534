"use client";

import { Button, FormControl, FormLabel, Sheet, Typography, Input, FormHelperText } from "@mui/joy";
import { sendResetEmailApi } from "network/api/app";
import { Link, useSearchParams } from "react-router-dom";
import React, { FormEvent, useState } from "react";
import AuthLayout from "components/layouts/AuthLayout";
import { Helmet } from "react-helmet";
import { log, logErr } from "utils/helpers";

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [searchParams] = useSearchParams();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    try {
      setLoading(true);
      const res = await sendResetEmailApi({ email });
      log("res", res);

      if (res?.type === "RXSUCCESS") {
        setSuccess("Sent! Please check your email.");
      } else if (res?.type === "RXERROR") {
        setError(res?.message);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("Something went wrong!");
      logErr("err", err);
    }
  };

  return (
    <AuthLayout>
      <Helmet>
        <title>Forgot password - YourGPT</title>
        <meta
          name="description"
          content="Forgot your YourGPT password? No problem. Reset your password in just a few simple steps and regain access to your account. Our secure process ensures your information is safe and protected. Follow the instructions provided and get back to enjoying all the benefits of your account."
        />
      </Helmet>
      <div className="px-5 text-center">
        <div className="mb-2 flex flex-col gap-2">
          <Typography fontWeight="xl" level="h3">
            Forgot Password?
          </Typography>
          <Typography
            sx={{
              mb: 2,
              color: "text.secondary",
            }}
          >
            Fear not, we'll email you instructions to reset your password
          </Typography>
        </div>

        <form className="mt-4 flex flex-col gap-3" onSubmit={onSubmit}>
          <FormControl required color={success ? "success" : "neutral"} error={error ? true : false}>
            <FormLabel>Email</FormLabel>
            <Input placeholder="Enter your email" type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <FormHelperText>{error}</FormHelperText>
            <FormHelperText>{success}</FormHelperText>
          </FormControl>

          <Button type="submit" fullWidth loading={loading}>
            Send Email
          </Button>
        </form>

        <div className="mt-10">
          <Typography fontWeight={"md"}>
            <Link to={`/login${searchParams.get("continue") ? `?continue=${searchParams.get("continue")}` : ""}`}>Back to login</Link>
          </Typography>
        </div>
      </div>
    </AuthLayout>
  );
}
