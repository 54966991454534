import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { THEME } from "utils/constants/ui";
import { ReactComponent as Logo } from "../assets/svg/logo.svg";
import { useEffect, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react";
import { useAuth } from "context/AuthContext";
import { FaUserCircle } from "react-icons/fa";

const Navbar = () => {
  const { logout, user } = useAuth();
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();
  const path = window.location.pathname;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Root className={`bg-white px-6 flex justify-between items-center fixed w-full z-10 transition-shadow duration-300 ${scrolled && path === "/products" ? "shadow-small" : "shadow-small"}`}>
      <div className="flex items-center">
        <Link to="/products">
          <Logo title="logo" className="h-9 fill-foreground-800" />
        </Link>
      </div>
      <div className="flex items-center gap-1">
        <nav className="hidden md:flex md:justify-center md:items-center gap-5">
          <div className="  cursor-pointer hover:scale-[1.03] active:scale-[1.05] ">
            <Dropdown size="lg" placement="bottom-end">
              <DropdownTrigger>
                <button className="flex items-center h-10 w-10 justify-center outline-none">
                  {user?.profile_pic ? <img src={user.profile_pic} alt="User profile" className="h-10 w-10 rounded-full shadow-sm cursor-pointer border-2" /> : <FaUserCircle className="text-foreground-500" size={26} />}
                </button>
              </DropdownTrigger>
              <DropdownMenu aria-label="Account Actions">
                <DropdownItem
                  onClick={() => {
                    navigate("/profile");
                  }}
                  key="profile"
                >
                  Profile
                </DropdownItem>
                <DropdownItem onClick={logout} key="logout" className="text-danger" color="danger">
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </nav>
      </div>
    </Root>
  );
};

export default Navbar;

const Root = styled.nav`
  height: ${THEME.navbarHeight}px;
`;
