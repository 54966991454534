import styled from "styled-components";
import { Link } from "react-router-dom";
import Navbar from "components/BackupNavbar";


export default function NotFound() {
  return (
    <div className=" bg-gradient-to-b from-primary1 to-transparent min-h-screen flex flex-col items-stretch">
      <div className="w-full">
        <Navbar />
      </div>
      <Root className="p-12 text-white flex-1">
        <h1 className="mb-8">Page Not Found</h1>
        <Link to="/products">
          <button className="bg-primary1 text-white p-3 px-6 rounded-xl opacity-90 hover:opacity-100 transition-all ">Go to Home</button>
        </Link>
      </Root>
    </div>
  );
}

const Root = styled.div`
  display: grid;
  place-content: center;
  justify-items: center;

  h1 {
    font-weight: 600;
    font-size: 42px;
    line-height: 51px;
  }

  img {
    width: 320px;
  }
`;
