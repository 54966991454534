"use client";

import { Alert, Typography } from "@mui/joy";
import React, { useState } from "react";

import { getAuth, signInWithPopup, GoogleAuthProvider, TwitterAuthProvider, GithubAuthProvider, getAdditionalUserInfo } from "firebase/auth";
import { firebaseApp } from "network/firebase";
import { log, logErr } from "utils/helpers";
import { loginApi, socialLoginApi } from "network/api/auth";
import { SocialLoginD } from "types/auth";
import { useAuth } from "context/AuthContext";
import githubImg from "assets/svg/github.svg";
import googleImg from "assets/svg/google.svg";
import twitterImg from "assets/svg/twitter.svg";
import OverlayLoader from "components/OverlayLoader";
import { useNavigate } from "react-router-dom";
import { ApiResType } from "types/enum";
import { on } from "events";
// import { useRouter } from "next/navigation";

let auth: any;

if (typeof window !== "undefined") {
  auth = getAuth(firebaseApp);
}

export default function SocialLoginBtns() {
  const { onLoginSuccess, onSignup } = useAuth();
  const navigate = useNavigate();
  const [loadingType, setLoadingType] = useState<SocialLoginD | null>(null);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState(false);

  const onLogin = async (type: SocialLoginD) => {
    try {
      setError(false);
      if (loadingType) {
        return;
      }

      let provider;

      switch (type) {
        case "google":
          provider = new GoogleAuthProvider();
          break;
        case "twitter":
          provider = new TwitterAuthProvider();
          break;
        case "github":
          provider = new GithubAuthProvider();
          break;
        default:
          provider = new GoogleAuthProvider();
      }

      setLoadingType(type);

      const result = await signInWithPopup(auth, provider);

      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      // The signed-in user info.
      const user = result.user;

      const addInfo = getAdditionalUserInfo(result);

      if (addInfo?.isNewUser) {
        onSignup({ email: user.email || "" });
      }

      // IdP data available using getAdditionalUserInfo(result)
      let nameArr = user?.displayName?.split(" ");

      let firstName;
      let lastName;

      if (typeof nameArr !== "undefined") {
        firstName = nameArr[0];
        lastName = nameArr.slice(1, nameArr.length).toString();
      }

      const res = await socialLoginApi({
        email: user.email || "",
        first_name: firstName,
        last_name: lastName,
        firebase_uid: user.uid,
        user: user.displayName || "",
        type: type,
      });

      setLoadingType(null);

      if (res.type === ApiResType.SUCCESS) {
        onLoginSuccess({ data: res.data, persist: true });
      }
      if (res.type === ApiResType.ERROR) {
        setError(true);
        setErrMsg(res.message);
      }
    } catch (err: any) {
      setLoadingType(null);
      logErr("Err", err);
      // Handle Errors here.
      const errorCode = err?.code;
      const errorMessage = err?.message;
      // The email of the user's account used.
      const email = err?.customData?.email;
      // The AuthCredential type that was used.
      // const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    }
  };

  const onGoogle = async () => {};
  const onGithub = async () => {};
  const onFacebook = async () => {};

  return (
    <div className="flex flex-col gap-2">
      {error && (
        <Alert variant="soft" color="danger" className="text-center">
          {errMsg}
        </Alert>
      )}
      <div className={itemS} onClick={() => onLogin("google")}>
        {loadingType === "google" && <OverlayLoader />}
        <img src={googleImg} className="aspect-square h-7" alt="google" />
        <Typography fontWeight={"md"}>Continue with Google</Typography>
      </div>
      {/* <div className={itemS} onClick={() => onLogin("twitter")}>
        {loadingType === "twitter" && <OverlayLoader />}
        <img src={twitterImg} className="aspect-square h-7" />
        <Typography fontWeight={"md"}>Continue with Twitter</Typography>
      </div> */}
      <div className={itemS} onClick={() => onLogin("github")}>
        {loadingType === "github" && <OverlayLoader />}
        <img src={githubImg} className="aspect-square h-7" alt="github" />
        <Typography fontWeight={"md"}>Continue with Github</Typography>
      </div>
    </div>
  );
}

const itemS = "overflow-hidden relative flex cursor-pointer items-center gap-3 rounded-md border border-solid border-gray-300 px-2 py-2 transition-all hover:gap-2 hover:bg-gray-200 justify-center";
