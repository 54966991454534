import { useAuth } from "context/AuthContext";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

export default function PostHogRegister() {
  const { user } = useAuth();
  const posthog = usePostHog();

  useEffect(() => {
    if (user?.email && user.id) {
      posthog?.identify(
        user.id, // Replace 'distinct_id' with your user's unique identifier
        { email: user?.email, name: user?.first_name + " " + user?.last_name } // optional: set additional user properties
      );
    }
  }, [user, posthog]);

  return <></>;
}
