import robot from "../../assets/images/chrome.webp";
import firefox from "../../assets/images/firefox.webp";
import Chatbotlogo from "../../assets/images/chatbot.svg";
import LLMlogo from "../../assets/images/LLMlogo.svg";
import { YourGptAppCard } from "./component/YourGptAppCard";
import { ProductExtensionCard } from "./component/ProductExtensionCard";

const Purpose = () => {
  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      {/* Right Section */}
      <main className="flex-1 bg-gray-100 flex flex-col md:pl-[100px] mt-[120px] md:mt-[60px]  ">
        <section className="">
          <div className="max-w-screen-xl mx-auto pt-6 px-6 ">
            <h3 className=" flex  items-center gap-2 font-semibold mb-2">
              {/* All <Logo title="logo" className="h-6 fillforeground-800" /> Apps */}
              YourGPT Apps
            </h3>
            <p className="text-gray-500 text-sm">Click 'Access Now' to start using any YourGPT application.</p>
          </div>
        </section>
        <div className="max-w-screen-xl mx-auto w-full px-6  ">
          <div className="w-full flex flex-wrap md:flex-nowrap justify-center  lg:justify-start gap-6 border-gray-400/10 border-b-2 py-8">
            {YOURGPTAPPSDATA.map((item) => (
              <YourGptAppCard key={item.id} item={item} />
            ))}
          </div>

          <div className="flex  flex-col  py-8 2xl:px-0 ">
            <h1 className=" font-semibold mb-5 text-black">Others</h1>
            <div className="flex flex-col l gap-5">
              {PRODUCTSEXTENSION.map((productextension, index) => (
                <ProductExtensionCard key={index} productextension={productextension} />
              ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Purpose;

const YOURGPTAPPSDATA = [
  {
    id: 1,
    name: "Chatbot",
    desc: "ChatGPT Chatbot for customer support",
    icon: (
      <div>
        <img className="h-12" src={Chatbotlogo} alt="chatbot-logo" />
      </div>
    ),
    link: "https://chatbot.yourgpt.ai",
  },

  {
    id: 2,
    name: "LLM Spark",
    desc: "Build Production Ready LLM Apps",
    icon: (
      <div>
        <img className="h-10 bg-transparent" src={LLMlogo} alt="robot" />
      </div>
    ),
    link: "https://llmspark.yourgpt.ai",
    beta: true,
  },
];

const PRODUCTSEXTENSION = [
  {
    imageUrl: Chatbotlogo, // Replace with your image path
    title: "Limited Time Offer",
    name: "YourGPT Chatbot Mobile App",
    description: "Get YourGPT Chatbot Mobile App for instant access.",
    price: "$699",
    buttonText: "Get Now",
    app: true,
  },
  {
    imageUrl: robot, // Replace with your image path
    title: "Limited Time Offer",
    name: "YourGPT Chatbot Chrome Extension",
    description: "Access your AI Knowledgebase Agent Instantly, using the chrome browser extension. ",
    price: "$699",
    buttonText: "Get Now",
    link: "https://chromewebstore.google.com/detail/yourgpt-ai/bhcadnbfklkhecfjnhiphdijgnlddegg?hl=en-US&utm_source=ext_sidebar",
  },
  {
    imageUrl: firefox, // Replace with your image path
    title: "Limited Time Offer",
    name: "YourGPT Chatbot Firefox Extension",
    description: "Get one click access to your AI Knowledgebase Agent, using the firefox browser extension.",
    price: "$699",
    buttonText: "Get Now",
    link: "https://addons.mozilla.org/en-US/firefox/addon/yourgpt-ai-knowledgebase-agent/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search",
  },
];
